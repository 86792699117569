import { website } from '@getpopsure/private-constants';
import { PrismicPreviewProps } from '@prismicio/next';
import { SliceZone } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import { captureException } from '@sentry/nextjs';
import MissingRequiredFields from 'components/MissingRequiredFields';
import { getLatestPostsByCategory } from 'endpoint';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { createPrismicClient } from 'prismicio';
import React from 'react';
import { components } from 'slices';
import ComponentManager from 'strapi/components/ComponentManager';
import { GetValues } from 'strapi/types/utils';
import { fetchOne } from 'strapi/utils/api';
import { isPageExcludedFromSiteMap } from 'strapi/utils/seo';
import { AnnouncementBarDocument } from 'types.generated';
import { isProduction } from 'util/env';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';

import { AnnouncementBar } from '../components/AnnoucementBar';
import { Homepage } from './homepage';
import styles from './style.module.scss';

const getWebsiteLdScript = () => {
  return {
    __html: `
    {
      "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "Feather Insurance",
      "alternateName": "Feather",
      "url" : "${website.base}",
      "publisher": { "@type": "Organization", "@id" : "${website.base}#organization"}
    }`,
  };
};

declare global {
  interface Window {
    snowplow: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    Trustpilot: unknown;
    opera: string;
    Intercom?: Intercom_.IntercomCommand;
  }
}
type StrapiHomepageData = GetValues<'api::homepage.homepage'>;

export type BlogPostType = {
  node: {
    id: number;
    link: string;
    title: string;
    excerpt: string;
    featuredImage?: {
      node: {
        sourceUrl: string;
        altText: string;
      };
    };
  };
};

const PrismicPage = ({ page }: { page: PrismicDocument }) => {
  const {
    seo_title: seoTitle,
    seo_description: seoDescription,
    og_description: ogDescription,
    og_image: ogImage,
    slices,
  } = page.data;

  if (
    !seoTitle ||
    !seoDescription ||
    !ogDescription ||
    Object.keys(ogImage).length === 0
  ) {
    return (
      <div className={`pt64 ${styles.container}`}>
        <MissingRequiredFields />;
      </div>
    );
  }

  return (
    <>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          description: ogDescription,
          images: [
            {
              url: ogImage.url,
              width: ogImage.width,
              height: ogImage.height,
              alt: ogImage.alt,
            },
          ],
        }}
      />
      <SliceZone slices={slices} components={components as never} />
    </>
  );
};

const StrapiPage = ({ page }: { page: StrapiHomepageData }) => {
  const { seo, tags } = page;

  if (!seo) {
    throw new Error('Missing homepage metadata');
  }

  const { metaTitle, metaDescription, metaSocial, canonicalURL } = seo;
  const isExcludedFromSiteMap = isPageExcludedFromSiteMap(tags?.tags_list);

  if (!metaSocial || metaSocial.length === 0) {
    throw Error('Missing social media homepage preview data');
  }

  const [openGraphItem] = metaSocial;

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        openGraph={{
          title: openGraphItem.title,
          description: openGraphItem.description,
          images: openGraphItem.image?.data
            ? [
                {
                  url: openGraphItem.image.data.attributes.url,
                  width: openGraphItem.image.data.attributes.width,
                  height: openGraphItem.image.data.attributes.height,
                  alt: openGraphItem.image.data.attributes.alternativeText,
                },
              ]
            : [],
        }}
        noindex={isExcludedFromSiteMap && isProduction}
        canonical={canonicalURL}
      />
      <ComponentManager blocks={page.items} />
    </>
  );
};

const Home = ({
  blogPosts,
  navOffset,
  germanPaths,
  homepageStrapi,
  homepagePrismic,
  announcementBar,
}: {
  blogPosts: BlogPostType[];
  navOffset?: number;
  germanPaths: Array<string>;
  homepageStrapi: StrapiHomepageData | null;
  homepagePrismic: PrismicDocument | null;
  announcementBar: AnnouncementBarDocument | null;
}) => {
  if (homepageStrapi || homepagePrismic) {
    return (
      <>
        <Head>
          <script
            type="application/ld+json"
            id="WebsiteLd"
            dangerouslySetInnerHTML={getWebsiteLdScript()} // eslint-disable-line react/no-danger
          />
        </Head>
        <div className="pt64">
          {announcementBar && <AnnouncementBar document={announcementBar} />}
          {homepageStrapi ? (
            <StrapiPage page={homepageStrapi} />
          ) : (
            <PrismicPage page={homepagePrismic as PrismicDocument} />
          )}
        </div>
      </>
    );
  }

  /* If the current locale doesn't have a CMS-based homepage we render the hardcoded version */
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          id="WebsiteLd"
          dangerouslySetInnerHTML={getWebsiteLdScript()} // eslint-disable-line react/no-danger
        />
      </Head>
      <NextSeo
        title="Health & Liability Insurance for Expats in Germany"
        description="Feather gives insurance advice for expats in Germany. Our digital insurance agent will help you get the right insurance policies for your needs."
      />
      <Homepage
        announcementBar={announcementBar}
        blogPosts={blogPosts}
        navOffset={navOffset}
        germanPaths={germanPaths}
      />
    </>
  );
};

export async function getStaticProps({
  locale,
  previewData,
}: {
  locale: string;
  previewData: PrismicPreviewProps;
}) {
  try {
    const blogPosts = await getLatestPostsByCategory();

    const isGerman = ['en-de', 'de-de'].includes(locale);
    const client = createPrismicClient({ previewData });

    /* Iniziatilizing cmsHomepage as null as undefined cannot be serialized as JSON */
    let homepageStrapi: StrapiHomepageData | null = null;
    let homepagePrismic: PrismicDocument | null = null;
    if (!isGerman) {
      homepageStrapi = await fetchOne('homepages', {
        uid: 'homepage',
        locale,
      });

      if (!homepageStrapi) {
        homepagePrismic = await client.getSingle('homepage', {
          lang: locale,
        });
      }
    }

    let announcementBar: AnnouncementBarDocument | null = null;
    try {
      announcementBar = await client.getByUID('announcement_bar', 'homepage', {
        lang: locale,
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      /* Silently catch the error */
    }

    return {
      props: {
        blogPosts,
        homepageStrapi,
        homepagePrismic,
        announcementBar,
        ...(await serverSideTranslations(
          i18nLocaleFormatter(locale) || 'en-DE',
          ['common', 'home']
        )),
      },
    };
  } catch (e) {
    captureException(e, {
      extra: {
        locale,
        description: 'Failed to build homepage during static generation',
      },
    });
    throw new Error('Failed to build homepage during static generation');
  }
}

export default Home;
