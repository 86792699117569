import { reviews } from '@getpopsure/private-constants';
import { StarFilledIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { REVIEW_SITES, reviewsBySite, ReviewSite } from 'constants/reviews';
import React from 'react';

import styles from './ReviewBadges.module.scss';

const ReviewBadges = () => {
  const getHomepageStarsNumber = (rating: number): number => {
    return Math.ceil(rating);
  };

  return (
    <article
      className={classNames(styles.container, 'mt40 d-flex gap32 jc-start')}
    >
      {REVIEW_SITES.map((reviewSite) => {
        return (
          <a
            href={(reviews as Record<ReviewSite, string>)[reviewSite]}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.ratingLink}
            key={reviewSite}
          >
            <div
              className={classNames(
                styles.singleReviewBadge,
                'd-flex ai-center'
              )}
            >
              <p className="p-h4 tc-grey-700">
                {reviewsBySite[reviewSite].rating}
              </p>
              <div
                className={classNames(
                  styles.ratingContainer,
                  'd-flex ai-start fd-column ta-left'
                )}
              >
                <p className={classNames(styles.ratingName, 'tc-grey-600')}>
                  {reviewsBySite[reviewSite].siteName}
                </p>
                <div>
                  {[
                    ...Array(
                      getHomepageStarsNumber(reviewsBySite[reviewSite].rating)
                    ),
                  ].map((_, index) => (
                    <StarFilledIcon
                      color="yellow-500"
                      noMargin
                      className={classNames(styles.star, {
                        [styles.trustpilotStar]: reviewSite === 'trustpilot',
                      })}
                      size={12}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </a>
        );
      })}
    </article>
  );
};

export default ReviewBadges;
