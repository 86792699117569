import { HeartIcon, ShieldIcon, SmartphoneIcon } from '@popsure/dirty-swan';
import { Trans, useTranslation } from 'next-i18next';

import styles from './UniqueSellingPoints.module.scss';

const UniqueSellingPoints = () => {
  const { t } = useTranslation();

  const uniqueSellingPoints = [
    {
      title: t('home:usp.flexible.title', 'Modern, flexible coverage'),
      description: (
        <p>
          {t(
            'home:usp.flexible.description',
            'When life changes, so should your insurance.'
          )}
        </p>
      ),
      icon: <ShieldIcon color="grey-900" size={20} noMargin />,
    },
    {
      title: t('home:usp.reliable.title', 'Reliable customer support'),
      description: (
        <p>
          {t(
            'home:usp.reliable.description',
            'There for you every step of the way, and when you need it the most.'
          )}
        </p>
      ),
      icon: <HeartIcon color="grey-900" size={20} noMargin />,
    },
    {
      title: t('home:usp.signup.title', 'Online sign-up'),
      description: (
        <Trans
          i18nKey="home:usp.signup.description"
          default="Say <1>tchüss</1> to pesky paperwork."
        >
          Say <span className="fs-italic">tchüss</span> to pesky paperwork.
        </Trans>
      ),
      icon: <SmartphoneIcon color="grey-900" size={20} noMargin />,
    },
  ];

  return (
    <>
      {uniqueSellingPoints.map((item, index) => (
        <div
          className={`d-flex gap16  ${index > 0 ? 'mt32' : ''}`}
          key={item.title}
        >
          <div className={styles.uspIcon}>{item.icon}</div>
          <div>
            <h3 className="p-h3">{item.title}</h3>
            <div className={`p-p tc-grey-600 wmx7 ${styles.description}`}>
              {item.description}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default UniqueSellingPoints;
