import classnames from 'classnames';
import images from 'content/homepage/images';
import Image, { StaticImageData } from 'next/image';
import { useTranslation } from 'next-i18next';

import styles from './DifferentiationCards.module.scss';

interface DifferentiationCard {
  title: string;
  description: string;
  image: {
    src: StaticImageData | string;
    alt: string;
    width?: number;
    height?: number;
  };
}

const DifferentiationCards = ({ imgLocaleKey }: { imgLocaleKey: string }) => {
  const { t } = useTranslation();

  const differentiationCards: DifferentiationCard[] = [
    {
      title: t(
        'home:differentiation.1.title',
        'Adjusting to a changing lifestyle'
      ),
      description: t(
        'home:differentiation.1.description',
        'Our work isn’t done after you purchase a policy. We’re here to make sure your coverage evolves with your lifestyle, whether it’s adding kids or figuring out how much coverage you need for life insurance.'
      ),
      image: {
        src: images.differentiation1[imgLocaleKey],
        alt: 'A woman who is holding a smartphone and a child sit next to each other on a couch while smiling',
      },
    },
    {
      title: t('home:differentiation.2.title', 'Transparent advice'),
      description: t(
        'home:differentiation.2.description',
        "Feather is built by expats who've navigated the insurance maze as you; that’s why our goal is to simplify your decision-making, whether in our recommendation tool or the advice you get from our experts."
      ),
      image: {
        src: images.differentiation2[imgLocaleKey],
        alt: 'Two people sit next to each other in front of a table, on which there are a laptop, some pens and some pieces of paper',
      },
    },
    {
      title: t('home:differentiation.3.title', 'Everything in one app'),
      description: t(
        'home:differentiation.3.description',
        'Managing policies, submitting claims, getting advice, adding family members, all in the same account.'
      ),
      image: {
        src: images.differentiation3[imgLocaleKey],
        alt: 'A preview of the Feather mobile app showing the "Your policies" section',
      },
    },
  ];

  return (
    <div className={classnames('mt32', styles.container)}>
      {differentiationCards.map((card) => {
        return (
          <div
            className={classnames(
              'd-flex ai-start jc-between fd-column',
              styles.cardContainer
            )}
            key={card.title}
          >
            <div className={classnames(styles.cardText)}>
              <div>
                <div className="p-h3 p--serif">{card.title}</div>
                <div className="p-p tc-grey-600 mt8">{card.description}</div>
              </div>
            </div>
            <div className={classnames('mt24', styles.cardImage)}>
              <Image
                src={card.image.src}
                alt={card.image.alt}
                loading="eager"
                width={420}
                style={{ maxWidth: '100%' }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DifferentiationCards;
